C<template>
  <div class="animated fadeIn">
    <b-nav tabs style="margin-bottom: -1px;">
      <b-nav-item :key="index" v-for="(item, index) in type_list" @click="changeType(item.index, item.min_day, item.max_day)" :active="selectedTabIndex == item.index" >{{ item.label }}</b-nav-item>
    </b-nav>
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'CustomerWebService/get_overdue_repayment_list_credit'" 
          :per-page=10 
          :fields="columns" 
          :columns="['firstname', 'lastname', 'register', 'phone', 'payment', 'payAmount', 'loanBalance', 'interestAmount', 'unduelossAmount', 'totalUnduelossAmount', 'sysUserName']"
          :filename="'Хугацаа хэтрэлт'"
          :startDate="minDate"
          :endDate="maxDate"
          :searchFields="['merchant', 'loanCreatedUser']"
          hover bordered  fixed caption="" 
        ></c-table>  
      </b-col>
    </b-row>

    <b-modal ref="note-modal" title="Тэмдэглэл" hide-footer size="xl">
        <Note :customerId="show_note_customer_id"></Note>        
    </b-modal>

  </div>
</template>

<script>

import moment from 'moment'
export default {
  name: 'LoanRepaymentsOverdue.list',
  data: function () {
    return {
      show_note_customer_id: 0,
      selectedTabIndex: 0,
      minDate: moment().subtract(10000, 'days').format('YYYY-MM-DD'),
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      columns: [
        { label: '№', key: 'index', class:"text-center" },
        { label: 'Merchant', key: 'merchantNameWithType'},
        { label: 'Нэр', key: 'nicename', sortable: true, sort_key:'firstname'  },
        { label: 'Регистр', key: 'registerNumber', sortable: true, sort_key:'register' },
        { label: 'Утас', key: 'phoneNumber', sortable: true, sort_key:'phone' },
        { label: 'Утас 2', key: 'emergencyNumber', sortable: true, sort_key:'phone2' },
        { label: 'Төлөх огноо', key: 'paymentDate', sortable: true, sort_key:'payment' },
        { label: 'Хэтэрсэн хоног', key: 'overdueDay'},
        { label: 'Төлөх дүн', key: 'payAmount', sortable: true, sort_key:'payAmount', class:"text-right" },
        { label: 'Зээлийн үлдэгдэл', key: 'loanBalance', sortable: true, sort_key:'loanBalance', class:"text-right" },
        { label: 'Хариуцсан ажилтан', key: 'sysUserName'},
        { label: 'Зээлийн ажилтан', key: 'sysUsernameL'},
        { label: 'Нэмэлт',key: 'fbToken', class:"text-center" },
        { label: 'Шүүх',key: 'file',class:"text-center"},
        // { label: 'Хүү', key: 'interestAmount', sortable: true, sort_key:'interestAmount', class:"text-right" },
        // { label: 'Алданги', key: 'unduelossAmount', sortable: true, sort_key:'unduelossAmount', class:"text-right" },
        // { label: 'Торгууль', key: 'totalUnduelossAmount', sortable: true, sort_key:'totalUnduelossAmount', class:"text-right" },
        // { label: 'Нийт дүн', key: 'totalAmount', sortable: true, sort_key:'totalAmount', class:"text-right" }
      ],     
      type_list: [
        {
          index: 0,
          min_day: 1,
          max_day: 10000,
          label: 'Бүгд'
        },
        {
          index: 1,
          min_day: 1,
          max_day: 15,
          label: '15 хүртэл хоног'
        },
        {
          index: 2,
          min_day: 16,
          max_day: 30,
          label: '16-30 хоног'
        },
        {
          index: 3,
          min_day: 31,
          max_day: 60,
          label: '31-60 хоног'
        },
        {
          index: 4,
          min_day: 61,
          max_day: 90,
          label: '61-90 хоног'
        },
        {
          index: 5,
          min_day: 91,
          max_day: 180,
          label: '91-180 хоног'
        },
        {
          index: 6,
          min_day: 181,
          max_day: 365,
          label: '181-365 хоног'
        },
        {
          index: 7,
          min_day: 366,
          max_day: 10000,
          label: '366+ хоног'
        },
      ]            
    }
  },
  created: function() {

    // if( )) {
    //     this.$data.columns.push(
          
    //     );
    //     this.$data.columns.push(
    //       { label: 'PDF1',key: 'noLoanHtml',class:"text-center"}
    //     );
    // }

    var _buttons = []
    if( this.checkPermission('admin.loan-repayment-overdue.note')) {
        _buttons.push({
            icon:'',
            label:'Тэмдэглэл',
            variant:'outline-primary',
            callback: this.showNote
          });
    }
  
    if( this.checkPermission('admin.customer.detail')) {
        _buttons.push({
            icon:'fa fa-id-card',
            label:'',
            title:'Хэрэглэгчийн дэлгэрэнгүй',
            variant:'outline-primary',
            route: 'admin.customer.detail',
            route_param: 'customerId'
          });
    }
   
    if( _buttons.length > 0) {
      this.$data.columns.push({ label: 'Үйлдэл', key: 'actions', buttons: _buttons, class:"text-center"},)
    }
  },
  methods: {  
    showNote(customerId) {
        this.$data.show_note_customer_id = customerId
        this.$refs['note-modal'].show()
    },
    hideNote() {
        this.$refs['note-modal'].hide()
    },
    changeType(index, minDay, maxDay) {
      this.$data.selectedTabIndex = index
      this.$data.minDate = moment().subtract(maxDay, 'days').format('YYYY-MM-DD')
      this.$data.maxDate = moment().subtract(minDay, 'days').format('YYYY-MM-DD')
    }
  }
}
</script>
